section.hero-section {
    min-height:400px;
    height: 1079px;

    .mod, .wrap {
        min-height:400px;
        height: 1079px;

        .display-4 {
            font-family: nunito, sans-serif;
            font-size: 58px;
            font-weight: 400;
            letter-spacing: 0.06em;
            line-height: 60px;
        }
        .btn {
            min-width: 256px;
            padding: 15px 0;
        }
    }
    @media screen and (max-width: 1600px) {
        & {
            height: 875px;
        }
        .mod, .wrap {
            height: 875px;
        }
    }
    // @media screen and (max-width: 1199px) {
    //     & {
    //         height: 63vw;
    //     }
    //     .mod, .wrap {
    //         height: 63vw;
    //     }
    // }
}