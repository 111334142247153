
section.services-section {
    height: 859px;
    padding: 200px 0;

    .mod {
        padding: 100px 0;
    }
    h2 {
        color: #323235;
    }
   .h4 {
        font-family: nunito, sans-serif;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.15em;
   }
   .d-flex {
        min-height: 226px;
   }
   .btn {
        min-width: 193px;
        padding: 15px 20px;
   }
}